/* Component Dependencies */
var transactionalFooterLinksTemplate = require('templates/transactionalFooterLinks.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'transactionalFooterLinks',
  template: {
    'transactionalFooterLinks': transactionalFooterLinksTemplate
  },
  unBindEvents: function ($) {
    var _self = this;
    $('*[data-component-id="' + _self.$el.data('component-id') + '"]').off();
    $('*[data-component-id="' + _self.$el.data('component-id') + '"] *').off();
  },
  bindEvents: function () {
    var _self = this,
    $window = $(window),
    $panelHeading = _self.$el.find('.toggle-data');
    $panelHeading.on('click', function() {
      _self.toggleAccordion($(this));
    });
    $window.on('resize', function () {
      _self.footerNavLinkHandler($panelHeading);
    });
  },

  /**
   * This method is called on window resize to reset the accordion state of footer navigation.
   * @param {Object} Accordion header element is passed
   * @return {Void}
   */
  footerNavLinkHandler: function footerNavLinkHandler(panelHeading) {
    var _self = this;
    // show footer links in medium and large screen
    if (window.innerWidth >= 769) {
      panelHeading.off('click').next('.t-footer-links-list').show()
    } else {
      panelHeading.next('.t-footer-links-list').hide();
      panelHeading.find('.t-footer-icon').removeClass('t-icon-arrow-up').addClass('t-icon-arrow-down').
        attr('aria-label', panelHeading.find('.t-footer-icon').data('accordionOpen'));
      panelHeading.off('click').on('click', function () {
        _self.toggleAccordion($(this));
      });
    }
  },

  /**
   * This method is used handle the click event on the panel heading and
   * to toggle the panel list items.
   * @param {Object} Accordion header element is passed
   * @return {Void}
   */
  toggleAccordion: function toggleAccordion(panelHeading) {
    var $this = panelHeading,
      isExpanded = $this.find('.t-footer-icon').hasClass('t-icon-arrow-down');
      if($(window).width() < 769) {
        $this.next('.t-footer-links-list').slideToggle('slow').end()
        .find('.t-footer-icon').toggleClass('t-icon-arrow-down t-icon-arrow-up')
        .attr('aria-label', (isExpanded ? panelHeading.find('.t-footer-icon').data('accordionClose') :
          panelHeading.find('.t-footer-icon').data('accordionOpen')));
      }
  }
});
