var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"l-padding-left-none l-display-inline-block t-font-s\">\n"
    + ((stack1 = __default(require("../../../../../../core/helpers/if-cond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ctaNewWindow") : depth0),"neq","yes",{"name":"if-cond","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":14,"column":28}}})) != null ? stack1 : "")
    + "                </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a class=\"t-alt-link\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\"> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "<span class=\"t-icon-arrow-right\"></span></a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                	<a class=\"t-alt-link\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" target=\"_blank\"> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "<span class=\"t-icon-arrow-right\"></span></a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tile-transactional-footer\">\n    <section class=\"t-border-bottom t-border-color-standard-110 t-footer-section clearfix\">\n        <h3 class=\"t-font-s t-line-height-m is-drawer-control toggle-data l-margin-none t-font-weight-bold t-extend-h3 l-padding-bottom-half\" role=\"button\">\n            <span class=\"t-footer-icon t-icon-arrow-down l-float-right l-l-display-none\" data-accordion-open=\""
    + alias2(alias1(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"openVal")), depth0))
    + "\" data-accordion-close=\""
    + alias2(alias1(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"closeVal")), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"openVal")), depth0))
    + "\"></span>\n            <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"header") : depth0), depth0))
    + "</span>\n        </h3>\n        <ul class=\"t-footer-links-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ctaLinks") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":16,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n    </section>\n</div>";
},"useData":true});